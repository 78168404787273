// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="footer text-primary-foreground py-6">
          <p className="text-white text-center md:flex-1 md:text-center">
            &copy; 2024 WebNova. Todos los derechos reservados.
          </p>
      </footer>
  );
};

export default Footer;

const Hero = () => {
  return (
    <div id="Hero" className="relative h-screen">
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="text-center text-white">
          <h1 className="text-5xl font-bold mb-4">Explora las Estrellas con WebNova</h1>
          <p className="text-lg">Soluciones Digitales Innovadoras y Escalables para tu Negocio</p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
